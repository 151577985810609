@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Marcellus&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Marcellus", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFCC00;
}

code {
  font-family: "Marcellus", serif;
}

.marcellus-regular {
  font-family: "Marcellus", serif;
  font-weight: 400;
  font-style: normal;
}

.fontAlpha {
  font-family: "Marcellus", serif !important;
}

.fontBeta {
  font-family: "Nunito", serif !important;
}

.fontGama {
  font-family: "Montserrat", serif !important;
}

.center {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  vertical-align: middle !important;
}

.carousel .control-arrow {
  display: none !important;
}

.form-select {
  font-weight: 400;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  border-color: #d7d7d7;
  min-width: 131px;
  border: 1px solid #555 !important;
  padding: .85rem 1rem;
  line-height: 1.5;
  color: #777;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none;
}

.form-label {
  color: #666;
  font-weight: 300;
  margin: 0 0 1.1rem;
}

.form-number {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;
  background-color: #fff;
  min-width: 131px;
  border: 1px solid #555 !important;
  height: 17px;
  padding: .85rem 1rem;
  line-height: 1.5;
  color: #777;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none;
}

html {
  scroll-behavior: smooth;
}

.custom-carousel .slide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-carousel .slide>div {
  flex: 1;
}

/* .carousel .control-dots .dot {
  display: none !important;
} */